import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import { Container, Row, Col } from "react-bootstrap";
import 'slick-carousel/slick/slick-theme.css';
import Investor1 from "../images/banner.webp";
import Investor2 from "../images/investment2.webp";
import Petroleumbanner from '../images/sc_banner.jpg';
import faq from '../images/frequently-asked-questions-solution-concept.webp';
import MetaTags from '../MetaTags';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import '../Stlye.css';
const Sustainability_Consulting = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const faqData = [
    {
      question: 'What is the typical investment range for angel investors?',
      answer: 'Angel investments can vary widely, but they typically range from tens of thousands to a few million dollars.',
    },
    {
      question: 'Do angel investors only invest in certain industries?',
      answer: "While some angel investors specialize in specific industries, many are open to diverse investment opportunities across various sectors.",
    },
    {
      question: 'How do angel investors evaluate potential investments?',
      answer: "Angel investors consider factors such as market potential, the strength of the founding team, competitive advantages, and growth prospects.",
    },
    {
      question: 'What equity stake do angel investors usually seek?',
      answer: "Angel investors typically seek an equity stake ranging from 10% to 30%, depending on the investment amount and the startup's valuation. ",
    },
    {
      question: 'What is the typical timeline for securing funding from angel investors?',
      answer: "The timeline can vary, but it typically takes several months from initial contact to closing the deal, depending on due diligence and negotiation processes. ",
    },
    // Add more FAQ items as needed
  ];
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div>
       <MetaTags
        title="Angel Investor Service Queensland, Australia | Smart Resources QLD"
        description="Connect with Queensland's angel investors or find angel funding for your startup. Smart Resources QLD bridges the gap for businesses & investors."
        keywords="Angel Investor Service Queensland, Australia"
        identifyUrl="https://smartresourcesqld.com.au/angel-investor"
        DCTitle="Angel Investor Service Queensland, Australia | Smart Resources QLD"
        DCDescription="Connect with Queensland's angel investors or find angel funding for your startup. Smart Resources QLD bridges the gap for businesses & investors."
        DCSubject="Angel Investor Service Queensland, Australia"
        Canonical="https://smartresourcesqld.com.au/angel-investor"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Angel Investor Service Queensland, Australia | Smart Resources QLD"
        ogDescription="Connect with Queensland's angel investors or find angel funding for your startup. Smart Resources QLD bridges the gap for businesses & investors."
        ogUrl="https://smartresourcesqld.com.au/angel-investor"
        name="robots" content="index, follow"
      />
      <section className="innr_banner" style={{
        backgroundImage: `url(${Petroleumbanner})`
      }}>
        <div className="page_title_innr">
          <div className="page_title">     Sustainability Consulting 
</div>
          <ul className="bread_crumb">
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/our-operations">Services</NavLink></li>
            <li>Sustainability Consulting </li>
          </ul>
        </div>
      </section>
      <section className="pricing servinnrbg">
        <div className="servinnng_middle">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="global_left serv_innr_heading">
                  <h1> Sustainability Consulting </h1>
                  <p>Smart Resources offers sustainability consulting services in Queensland, focusing on eco-friendly strategies to improve resource efficiency and promote social responsibility through scrap metal recycling.<br /><br />
                    <p >Recycling valuable metals like steel, aluminum, copper, and brass helps businesses reduce their environmental impact by minimizing waste and lowering production costs. It conserves energy, requiring less than ore extraction and refining—crucial for enhancing energy efficiency in Queensland. 

</p></p>

                  

                  <p>
Our consultancy helps businesses optimize scrap metal collection, sorting, and processing, ensuring maximum resource recovery. We also guide clients in complying with local recycling regulations, facilitating a seamless transition to sustainable practices. By adopting effective scrap metal recycling strategies, companies can boost their corporate social responsibility, improve profitability, and contribute to a circular economy focused on sustainability.</p>
                  
                </div>
              </div>


            </div>
          </div>
        </div>
        <div className="discuss-seo seo_price_bg">
          <div className="container">
            <div className="row discuss-ppc-seo">
              <h2>
                Ready to Fuel Innovation as an Angel Investor?
              </h2>
              <div className="button-ppc-new">
                <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Explore Opportunities</NavLink>
                {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
              </div>
            </div>
          </div>
        </div>
        <section className="include_services">
          <div className='include_service_innr'>
            <div className="container">
               
            </div>

          </div>
           
          <div className="faqContainer">
          <Container>
            <Row>
              <Col lg="8" md="6" sm="12" className='accordion_left'>
                <div className="accordion">
                  <h1>Frequently Asked Questions</h1>
                  {faqData.map((item, index) => (
                    <div key={index} className="accordion-item">
                      <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                        <h2>{item.question}</h2>
                        {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                      </div>
                      {openIndex === index && <div style={{ fontSize: '18px' }} className="accordion-answer">{item.answer}</div>}
                    </div>
                  ))}
                </div>
              </Col>
              <Col lg="4" md="6" sm="12" className='accordion_right'>
                <div className='faq_Image'>
                  <img
                    src={faq}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </Container>

        </div>
        </section>
        
      </section>
    </div>
  );
};

export default Sustainability_Consulting;
