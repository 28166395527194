import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import './Stlye.css';
import realestate1 from './images/real-estate1.webp';
import backgroundImage from './images/theme-13.webp';
import mining1 from './images/mining1.webp';
import fuelprice from './images/fuel-price.webp';
import angel from './images/angel-investor.webp';
import consulting from './images/aew.webp';
import datacons from './images/data_cons.webp';
import { NavLink } from 'react-router-dom';
import { CustomPrevArrow, CustomNextArrow } from './customArrow';

const ServicesSection = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    arrows: true,
    centerMode: true,
    centerPadding: '20px',
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slides = [
    {
      title: 'Real Estate Consulting',
      image: realestate1,
      description:
        'Unlock the potential of your property investments with our expert guidance and strategic insights tailored to your real estate goals.',
      link: '/real-estate-consulting',
    },
    {
      title: 'Mining Consulting',
      image: mining1,
      description:
        'Navigate the complexities of the mining industry with our specialized advice and innovative solutions, driving efficiency and maximizing returns on your projects.',
      link: '/mining-consulting',
    },
    {
      title: 'Petroleum Consulting',
      image: fuelprice,
      description:
        'Stay ahead in the dynamic petroleum sector with our comprehensive consultancy services, offering strategic direction and technical expertise for sustainable growth.',
      link: '/petroleum-consulting',
    },
    {
      title: 'Data Science',
      image: datacons,
      description:
        'Harness the power of data to drive informed decision-making and gain a competitive edge in your industry, with our data science customized to your specific needs.',
      link: '/data-science',
    },
    {
      title: 'Angel Investor',
      image: angel,
      description:
        'Empower promising ventures and fuel innovation as an angel investor with Smart Resources, leveraging our network, expertise, and resources for impactful investments.',
      link: '/angel-investor',
    },

    {
      title: 'Sustainability Consulting',
      image: consulting,
      description:
        'Sustainability consulting helps businesses develop eco-friendly strategies to reduce environmental impact, improve resource efficiency, and promote social responsibility, driving long-term growth and positive environmental change.',
      link: '/sustainability',
    },

  ];

  return (
    <section className="services" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="innr_services">
        <div className="serv_heading">
          <div className="serv_heading_left">
            <div className="about_left_heading">
              <div className="abt_sub_title">Services</div>
              <h3 data-aos="fade-right" aos-anchor-placement="top-center">
                We deal with many services to take extra care of your problem solutions
              </h3>
            </div>
          </div>
          <div className="serv_heading_right">
            <div className="about_right_heading121" data-aos="fade-right" aos-anchor-placement="top-center">
              <p>
                Discover our range of specialized services designed to propel your business forward. From strategic consulting to innovative solutions, we're committed to delivering excellence tailored to your unique needs.
              </p>
            </div>
          </div>
        </div>

        <Slider ref={sliderRef} {...settings} className="slick_serv">
          {slides.map((slide, index) => (
            <div key={index} className="slick_innr_serv">
              <div className="item--icon">
                <img src={slide.image} alt={slide.title} />
              </div>
              <h3>
                <NavLink to={slide.link}>{slide.title}</NavLink>
              </h3>
              <div className="item_content_serv">
                <p>{slide.description}</p>
                <div className="entry-readmore">
                  <NavLink to={slide.link} onClick={() => window.scrollTo(0, 0)}>
                    Read more <i className="fa-solid fa-angles-right"></i>
                  </NavLink>
                  <NavLink to={slide.link} onClick={() => window.scrollTo(0, 0)} className="text-gradient">
                    Read more <i className="fa-solid fa-angles-right"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default ServicesSection;
